var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"900px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_vm._t("activator",null,null,{ on: on })]}}],null,true),model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('v-toolbar-title',{staticClass:"title primary--text"},[_vm._v(" Planning session de rendez-vous ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","icon":""},on:{"click":function($event){_vm.isOpen = false}}},on),[_c('v-icon',[_vm._v(" far fa-times-circle ")])],1)]}}])},[_vm._v(" Fermer la fenêtre ")])],1),_c('v-divider',{staticClass:"mb-4"}),(_vm.appointment !== null)?_c('v-card-text',[_c('p',{staticClass:"font-italic"},[_vm._v(" "+_vm._s(_vm.appointment.subject)+" du programme "+_vm._s(_vm.appointment.programs[0].title)+" par l'intervenant "+_vm._s(_vm.appointment.intervenants[0].name)+" "+_vm._s(_vm.appointment.intervenants[0].firstname)+" "),_c('br'),_vm._v(" Du "+_vm._s(_vm._f("toDay")(_vm.appointment.minDate))+" "+_vm._s(_vm._f("toDate")(_vm.appointment.minDate))+" au "+_vm._s(_vm._f("toDay")(_vm.appointment.maxDate))+" "+_vm._s(_vm._f("toDate")(_vm.appointment.maxDate))+", durée estimée de "+_vm._s(_vm._f("minutesToHours")(_vm.appointment.duration))+" par rendez-vous ")]),_c('v-row',{staticClass:"black--text"},[_c('v-col',[_c('span',{staticClass:"subtitle-1"},[_vm._v(" Disponibilités de "+_vm._s(_vm.appointment.intervenants[0].name)+" "+_vm._s(_vm.appointment.intervenants[0].firstname)+" "),_c('div',{staticClass:"float-right"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"blue","small":"","text":"","icon":""}},on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" fas fa-print ")])],1)]}}],null,false,307962740)},[_vm._v(" Imprimer ")]),_c('CreateSingleDisponibilityMenu',{attrs:{"userId":_vm.appointment.intervenants[0].id},on:{"created":function($event){return _vm.fetchAppointment()}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"blue","small":"","text":"","icon":""}},Object.assign({}, tooltip, dialog)),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" fas fa-plus ")])],1)]}}],null,true)},[_vm._v(" Ajouter une disponibilité ")])]}}],null,false,1168776974)})],1)]),_c('v-sheet',{staticClass:"pa-2 rounded",attrs:{"color":"grey lighten-3"}},[_c('table',[_c('tbody',_vm._l((_vm.parsedDays),function(day){return _c('tr',{key:day.key},[_c('td',[_vm._v(" "+_vm._s(_vm._f("toDayMonth")(day.key))+" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"x-small":"","text":"","icon":""},on:{"click":function($event){return _vm.copyDisponibilitiesToNextDay(day.disponibilities)}}},on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" fas fa-arrow-right ")])],1)]}}],null,true)},[_vm._v(" Copier sur le jour suivant ")]),_c('ConfirmationDialog',{attrs:{"text":"Voulez-vous vraiment supprimer toutes les disponibilités de ce jour ?"},on:{"confirm":function($event){_vm.deleteDisponibilities(day.disponibilities.map(function (d) { return d.id; }))}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"color":"red","x-small":"","text":"","icon":""}},Object.assign({}, dialog, tooltip)),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" fas fa-times ")])],1)]}}],null,true)},[_vm._v(" Supprimer ")])]}}],null,true)}),_c('table',{staticClass:"ml-5 my-2"},[_c('tbody',_vm._l((day.disponibilities),function(disponibility){return _c('tr',{key:disponibility.startDate},[_c('td',[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"green","x-small":""}},[_vm._v(" fas fa-circle ")]),_vm._v(" "+_vm._s(_vm._f("toTime")(disponibility.startDate,false))+" à "+_vm._s(_vm._f("toTime")(disponibility.endDate,false))+" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"x-small":"","text":"","icon":""},on:{"click":function($event){return _vm.copyDisponibilitiesToNextDay([disponibility])}}},on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" fas fa-arrow-right ")])],1)]}}],null,true)},[_vm._v(" Copier sur le jour suivant ")]),_c('ConfirmationDialog',{attrs:{"text":"Voulez-vous vraiment supprimer cette disponibilité ?"},on:{"confirm":function($event){return _vm.deleteDisponibilities([disponibility.id])}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"color":"red","x-small":"","text":"","icon":""}},Object.assign({}, dialog, tooltip)),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" fas fa-times ")])],1)]}}],null,true)},[_vm._v(" Supprimer ")])]}}],null,true)}),_c('table',{staticClass:"ml-5 my-2"},[_c('tbody',_vm._l((disponibility.instances),function(instance){return _c('tr',{key:instance.id},[_c('td',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"color":_vm.getAppointmentStatusColor(instance.status),"x-small":""}},on),[_vm._v(" fas fa-calendar ")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.getAppointmentStatusLabel(instance.status))+" ")]),_vm._v(" "+_vm._s(_vm._f("toTime")(instance.startDate,false))+" à "+_vm._s(_vm._f("toTime")(instance.endDate,false))+" - "+_vm._s(instance.buyer.name)+" "+_vm._s(instance.buyer.firstname)+" ")],1)])}),0)])],1)])}),0)])],1)])}),0)])])],1),_c('v-col',[_c('span',{staticClass:"subtitle-1"},[_vm._v(" Acquéreurs sélectionnés "),_c('div',{staticClass:"float-right"},[_c('AddBuyerMenu',{attrs:{"appointment":_vm.appointment},on:{"created":function($event){_vm.hasMadeModification = true; _vm.fetchAppointment();}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"blue","small":"","text":"","icon":""}},Object.assign({}, tooltip, dialog)),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" fas fa-plus ")])],1)]}}],null,true)},[_vm._v(" Ajouter un acquéreur ")])]}}],null,false,1528338081)})],1)]),_c('v-sheet',{staticClass:"pa-2 rounded",attrs:{"color":"grey lighten-3"}},[_c('table',[_c('tbody',_vm._l((_vm.appointment.buyers),function(buyer){return _c('BuyerSessionRow',{key:buyer.email,attrs:{"appointment":_vm.appointment,"buyer":buyer,"instance":_vm.getBuyerInstance(buyer.email)},on:{"deleted":function($event){_vm.hasMadeModification = true; _vm.fetchAppointment();}}})}),1)])])],1)],1)],1):_vm._e(),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"small":""},on:{"click":function($event){_vm.isOpen = false}}},[_vm._v(" Fermer ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }