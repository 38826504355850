<template>
    <v-dialog v-model="isOpen" width="900px">
        <template v-slot:activator="{ on }">
            <slot name="activator" v-bind="{ on }" />
        </template>

        <v-card>
            <v-toolbar dense flat>
                <v-toolbar-title class="title primary--text">
                    Planning session de rendez-vous
                </v-toolbar-title>

                <v-spacer />

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="isOpen = false" color="primary" icon v-on="on">
                            <v-icon> far fa-times-circle </v-icon>
                        </v-btn>
                    </template>
                    Fermer la fenêtre
                </v-tooltip>
            </v-toolbar>

            <v-divider class="mb-4" />

            <v-card-text v-if="appointment !== null">
                <p class="font-italic">
                    {{ appointment.subject }} du programme {{ appointment.programs[0].title }}
                    par l'intervenant {{ appointment.intervenants[0].name }} {{ appointment.intervenants[0].firstname }} <br>
                    Du {{ appointment.minDate | toDay() }} {{ appointment.minDate | toDate() }}
                    au {{ appointment.maxDate | toDay() }} {{ appointment.maxDate | toDate() }},
                    durée estimée de {{ appointment.duration | minutesToHours() }} par rendez-vous
                </p>

                <v-row class="black--text">
                    <v-col>
                        <span class="subtitle-1">
                            Disponibilités de {{ appointment.intervenants[0].name }} {{ appointment.intervenants[0].firstname }}

                            <div class="float-right">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-btn color="blue" small text icon v-on="on">
                                            <v-icon x-small> fas fa-print </v-icon>
                                        </v-btn>
                                    </template>
                                    Imprimer
                                </v-tooltip>

                                <CreateSingleDisponibilityMenu :userId="appointment.intervenants[0].id" @created="fetchAppointment()">
                                    <template v-slot:activator="{ on: dialog }">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on: tooltip }">
                                                <v-btn color="blue" small text icon v-on="{ ...tooltip, ...dialog }">
                                                    <v-icon x-small> fas fa-plus </v-icon>
                                                </v-btn>
                                            </template>
                                            Ajouter une disponibilité
                                        </v-tooltip>
                                    </template>
                                </CreateSingleDisponibilityMenu>
                            </div>
                        </span>

                        <v-sheet class="pa-2 rounded" color="grey lighten-3">
                            <table>
                                <tbody>
                                    <tr v-for="day of parsedDays" :key="day.key">
                                        <td>
                                            {{ day.key | toDayMonth() }}

                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn @click="copyDisponibilitiesToNextDay(day.disponibilities)" class="ml-2" x-small text icon v-on="on">
                                                        <v-icon x-small> fas fa-arrow-right </v-icon>
                                                    </v-btn>
                                                </template>
                                                Copier sur le jour suivant
                                            </v-tooltip>

                                            <ConfirmationDialog @confirm="deleteDisponibilities(day.disponibilities.map((d) => d.id))" text="Voulez-vous vraiment supprimer toutes les disponibilités de ce jour ?">
                                                <template v-slot:activator="{ on: tooltip }">
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on: dialog }">
                                                            <v-btn class="ml-2" color="red" x-small text icon v-on="{ ...dialog, ...tooltip }">
                                                                <v-icon x-small> fas fa-times </v-icon>
                                                            </v-btn>
                                                        </template>
                                                        Supprimer
                                                    </v-tooltip>
                                                </template>
                                            </ConfirmationDialog>

                                            <table class="ml-5 my-2">
                                                <tbody>
                                                    <tr v-for="disponibility of day.disponibilities" :key="disponibility.startDate">
                                                        <td>
                                                            <v-icon class="mr-2" color="green" x-small>
                                                                fas fa-circle
                                                            </v-icon>

                                                            {{ disponibility.startDate | toTime(false) }} à {{ disponibility.endDate | toTime(false) }}

                                                            <v-tooltip top>
                                                                <template v-slot:activator="{ on }">
                                                                    <v-btn @click="copyDisponibilitiesToNextDay([disponibility])" class="ml-2" x-small text icon v-on="on">
                                                                        <v-icon x-small> fas fa-arrow-right </v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                Copier sur le jour suivant
                                                            </v-tooltip>

                                                            <ConfirmationDialog @confirm="deleteDisponibilities([disponibility.id])" text="Voulez-vous vraiment supprimer cette disponibilité ?">
                                                                <template v-slot:activator="{ on: tooltip }">
                                                                    <v-tooltip top>
                                                                        <template v-slot:activator="{ on: dialog }">
                                                                            <v-btn class="ml-2" color="red" x-small text icon v-on="{ ...dialog, ...tooltip }">
                                                                                <v-icon x-small> fas fa-times </v-icon>
                                                                            </v-btn>
                                                                        </template>
                                                                        Supprimer
                                                                    </v-tooltip>
                                                                </template>
                                                            </ConfirmationDialog>

                                                            <table class="ml-5 my-2">
                                                                <tbody>
                                                                    <tr v-for="instance of disponibility.instances" :key="instance.id">
                                                                        <td>
                                                                            <v-tooltip top>
                                                                                <template v-slot:activator="{ on }">
                                                                                    <v-icon class="mr-2" :color="getAppointmentStatusColor(instance.status)" x-small v-on="on">
                                                                                        fas fa-calendar
                                                                                    </v-icon>
                                                                                </template>
                                                                                {{ getAppointmentStatusLabel(instance.status) }}
                                                                            </v-tooltip>
                                                                            
                                                                            {{ instance.startDate | toTime(false) }} à {{ instance.endDate | toTime(false) }} - {{ instance.buyer.name }} {{ instance.buyer.firstname }}
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>

                                    </tr>
                                </tbody>
                            </table>
                        </v-sheet>
                    </v-col>

                    <v-col>
                        <span class="subtitle-1">
                            Acquéreurs sélectionnés

                            <div class="float-right">
                                <AddBuyerMenu :appointment="appointment" @created="hasMadeModification = true; fetchAppointment();">
                                    <template v-slot:activator="{ on: dialog }">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on: tooltip }">
                                                <v-btn color="blue" small text icon v-on="{ ...tooltip, ...dialog }">
                                                    <v-icon x-small> fas fa-plus </v-icon>
                                                </v-btn>
                                            </template>
                                                Ajouter un acquéreur
                                        </v-tooltip>
                                    </template>
                                </AddBuyerMenu>
                            </div>
                        </span>

                        <v-sheet class="pa-2 rounded" color="grey lighten-3">
                            <table>
                                <tbody>
                                    <BuyerSessionRow v-for="buyer of appointment.buyers" :key="buyer.email" :appointment="appointment" :buyer="buyer" :instance="getBuyerInstance(buyer.email)" @deleted="hasMadeModification = true; fetchAppointment();" />
                                </tbody>
                            </table>
                        </v-sheet>
                    </v-col>
                </v-row>

            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-spacer />

                <v-btn @click="isOpen = false" small> Fermer </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { isWithinInterval, addDays, format } from 'date-fns';

import appointmentMixin from '../../mixins/appointments.js';
import AddBuyerMenu from './AddBuyerMenu.vue';
import BuyerSessionRow from './BuyerSessionRow.vue';
import ConfirmationDialog from '../dialogs/ConfirmationDialog';
import CreateSingleDisponibilityMenu from './CreateSingleDisponibilityMenu.vue';

export default {
    name: 'ManageAppointmentSessionPlanningDialog',

    mixins: [appointmentMixin],

    props: {
        appointmentId: { type: Number, required: true }
    },

    components: {
        AddBuyerMenu,
        BuyerSessionRow,
        ConfirmationDialog,
        CreateSingleDisponibilityMenu
    },

    data: () => ({
        isOpen: false,
        hasMadeModification: false,

        appointment: null,
        disponibilities: [],

        parsedDays: []
    }),

    watch: {
        isOpen() {
            if (this.isOpen) {
                this.hasMadeModification = false;
                this.fetchAppointment();
            } else {
                if (this.hasMadeModification) {
                    this.$emit('updated');
                }
            }
        }
    },

    methods: {
        async fetchAppointment() {
            try {
                this.setLoading(true);

                const query = {
                    include: 'programs,intervenants,instances,buyers',
                    appointments: [this.appointmentId]
                };

                const { appointments: [appointment], err } = await this.repos.appointments.getAppointments(query);
                if (err) {
                    throw new Error(err);
                }
                this.appointment = appointment;
                this.fetchIntervenantDisponibilities();
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors du chargement de la session de rendez-vous',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        },

        async fetchIntervenantDisponibilities() {
            try {
                this.setLoading(true);

                const { disponibilities, err } = await this.repos.appointments.getIntervenantDisponibilities(this.appointment.intervenants[0].id);
                if (err) {
                    throw new Error(err);
                }
                this.disponibilities = disponibilities;
                this.parseDisponibilities();
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors du chargement de la session de rendez-vous',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        },

        async deleteDisponibilities(disponibilities) {
            try {
                this.setLoading(true);

                const body = {
                    disponibilities
                };
                const { err } = await this.repos.appointments.deleteDisponibilities(body);
                if (err) {
                    throw new Error(err);
                }
                this.hasMadeModification = true;
                this.fetchIntervenantDisponibilities();
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors de la suppression de la disponibilité',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        },

        async createDisponibilities(disponibilities) {
            try {
                this.setLoading(true);

                const body = {
                    disponibilities
                };

                const { err } = await this.repos.appointments.createDisponibility(body);
                if (err) {
                    throw new Error(err);
                }

                let message = 'Votre nouvelle disponibilité a bien été enregistrée';
                if (disponibilities.length > 1) {
                    message = 'Vos nouvelles disponibilités ont bien été enregistrées';
                }
                this.$notify({
                    title: 'Information',
                    text: message,
                    type: 'success'
                });
                this.hasMadeModification = true;
                this.fetchIntervenantDisponibilities();
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors de la modification d\'une disponibilité',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        },

        async copyDisponibilitiesToNextDay(disponibilities) {
            const newDisponibilities = JSON.parse(JSON.stringify(disponibilities)).map((d) => ({
                userId: this.appointment.intervenants[0].id,
                startDate: d.startDate,
                endDate: d.endDate
            }));

            for (const disponibility of newDisponibilities) {
                disponibility.startDate = format(addDays(new Date(disponibility.startDate), 1), 'yyyy-MM-dd HH:mm');
                disponibility.endDate = format(addDays(new Date(disponibility.endDate), 1), 'yyyy-MM-dd HH:mm');
            }

            await this.createDisponibilities(newDisponibilities);
        },

        parseDisponibilities() {
            const days = [];

            for (const disponibility of this.disponibilities) {
                const key = this.$options.filters.toDateDatabase(disponibility.startDate);
                const existingDay = days.find((d) => d.key === key);

                for (const instance of this.appointment.instances) {
                    if (isWithinInterval(new Date(instance.startDate), { start: new Date(disponibility.startDate), end: new Date(disponibility.endDate) })) {
                        if (!Array.isArray(disponibility.instances)) {
                            disponibility.instances = [instance];
                        } else {
                            disponibility.instances.push(instance);
                        }
                    }
                }

                if (existingDay) {
                    existingDay.disponibilities.push(disponibility);
                } else {
                    days.push({
                        key,
                        disponibilities: [disponibility]
                    });
                }
            }

            days.sort((a, b) => a.key.localeCompare(b.key));

            for (const day of days) {
                day.disponibilities.sort((a, b) => a.startDate.localeCompare(b.startDate));
            }
            
            this.parsedDays = days;
        },

        getBuyerInstance(buyerEmail) {
            return this.appointment.instances.find((i) => i.buyerEmail === buyerEmail);
        }
    }
};
</script>
