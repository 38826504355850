<template>
    <div class="px-4">
        <h2 class="mt-2"> Sessions de rendez-vous </h2>

        <p class="mt-2">
            Visualisez et organisez ici les sessions de rendez-vous d'accompagnement de vos acquéreurs.
        </p>

        <v-card class="d-flex align-center pa-2">
            <v-text-field v-model="search.quickSearch" @keyup="doSearch()" class="flex-grow-0 mr-2" label="Recherche" color="app-blue" prepend-inner-icon="fas fa-search" outlined hide-details dense />

            <v-select v-model="search.subjects" @change="doSearch()" :items="['Choix et options','Visite cloisons','Visite pré-livraison','Livraison et remise des clés']" label="Objet" class="flex-grow-0 mr-2" outlined hide-details dense :menu-props="{ bottom: true, offsetY: true }" multiple />
            <ProgramAutocomplete v-model="search.programs" @input="doSearch()" ref="programAutocomplete" label="Programmes" class="flex-grow-0 mr-2" multiple />
            <UserAutocomplete v-model="search.intervenants" @input="doSearch()" ref="userAutocomplete" label="Intervenants" class="flex-grow-0 mr-2" multiple />

            <v-switch v-model="search.passedSessions" @change="doSearch()" label="Sessions passées" />
            <v-switch v-model="search.futureSessions" @change="doSearch()" label="Sessions en cours" />

            <CreateAppointmentSessionDialog @created="fetchAppointments()">
                <template v-slot:activator="{ on: dialog }">
                    <v-tooltip top>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn color="primary" class="mr-2" small v-on="{ ...dialog, ...tooltip }">
                                Nouvelle session
                            </v-btn>
                        </template>
                        Créer une nouvelle session
                    </v-tooltip>
                </template>
            </CreateAppointmentSessionDialog>

            <v-spacer />

            <v-select class="sort-pagination d-inline-flex flex-grow-0" v-model="order" label="Trier par" :menu-props="{ bottom: true, offsetY: true }" :items="sortList" background-color="white" @change="fetchAppointments(true)" hide-details outlined dense>
                <template v-slot:append-outer>
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn @click="toggleOrder()" icon v-on="on" class="ml-2">
                                <v-icon v-bind:class="{'fa-rotate-180': by === 'asc'}">
                                    fas {{ by === 'asc' ? 'fa-sort-amount-down-alt': 'fa-sort-amount-down' }}
                                </v-icon>
                            </v-btn>
                        </template>
                        <template v-if="by === 'asc'">
                            Croissant
                        </template>
                        <template v-if="by === 'desc'">
                            Décroissant
                        </template>
                    </v-tooltip>
                </template>
            </v-select>

            <v-pagination v-model="page" @input="fetchAppointments(true, false)" :length="totalPages" total-visible="5" />
        </v-card>

        <v-simple-table class="rounded elevation-2 my-4">
            <template>
                <thead>
                    <tr>
                        <th>
                            Objet
                        </th>

                        <th>
                            Programme
                        </th>

                        <th>
                            Intervenant
                        </th>

                        <th>
                            Période
                        </th>

                        <th>
                            Acquéreurs
                        </th>

                        <th style="width: 1%;">
                            Actions
                        </th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="appointment of appointments" :key="appointment.id">
                        <!-- Objet  -->
                        <td>
                            {{ appointment.subject }}
                        </td>

                        <!-- Programme -->
                        <td>
                            <span v-if="appointment.programs.length > 0">
                                {{ appointment.programs[0].title }}
                            </span>
                        </td>

                        <!-- Intervenant -->
                        <td>
                            <span v-if="appointment.intervenants.length > 0">
                                <ManageAppointmentSessionPlanningDialog :appointmentId="appointment.id" @updated="fetchAppointments()">
                                    <template v-slot:activator="{ on: dialog }">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on: tooltip }">
                                                <v-chip class="mr-1" label small v-on="{ ...tooltip, ...dialog }">
                                                    <v-icon> fas fa-calendar </v-icon>
                                                </v-chip>
                                            </template>
                                            Cliquez pour afficher et gérer le planning de l'intervenant
                                        </v-tooltip>
                                    </template>
                                </ManageAppointmentSessionPlanningDialog>
                                
                                {{ appointment.intervenants[0].name }}
                                {{ appointment.intervenants[0].firstname }}
                            </span>
                        </td>

                        <!-- Période -->
                        <td>
                            du {{ appointment.minDate | toDay() }} {{ appointment.minDate | toDate() }} <br>
                            au {{ appointment.maxDate | toDay() }} {{ appointment.maxDate | toDate() }}
                        </td>

                        <!-- Acquéreurs -->
                        <td>
                            <ManageAppointmentSessionPlanningDialog :appointmentId="appointment.id" @updated="fetchAppointments()">
                                <template v-slot:activator="{ on: dialog }">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on: tooltip }">
                                            <v-chip class="mr-1" label small v-on="{ ...tooltip, ...dialog }">
                                                <v-icon left> fas fa-users </v-icon>
                                                {{ appointment.buyers.length }}
                                            </v-chip>
                                        </template>

                                        <template v-if="appointment.buyers.length === 0">
                                            Session de rendez-vous proposée à aucun acquéreur
                                        </template>
                                        <template v-else-if="appointment.buyers.length === 1">
                                            Session de rendez-vous proposée à un acquéreur
                                        </template>
                                        <template v-else>
                                            Session de rendez-vous proposée à {{ appointment.buyers.length }} acquéreurs
                                        </template>

                                        <br>
                                        Cliquez pour visualiser et sélectionner les acquéreurs concernés
                                    </v-tooltip>
                                </template>
                            </ManageAppointmentSessionPlanningDialog>

                            <ManageAppointmentSessionPlanningDialog :appointmentId="appointment.id" @updated="fetchAppointments()">
                                <template v-slot:activator="{ on: dialog }">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on: tooltip }">
                                            <v-chip class="mr-1" label small v-on="{ ...tooltip, ...dialog }">
                                                <v-icon left> fas fa-calendar </v-icon>
                                                {{ appointment.instances.length }}
                                            </v-chip>
                                        </template>

                                        <template v-if="appointment.instances.length === 0">
                                            Aucun acquéreur n'a planifié de rendez-vous
                                        </template>
                                        <template v-else-if="appointment.instances.length === 1">
                                            1 acquéreur a planifié son rendez-vous
                                        </template>
                                        <template v-else>
                                            {{ appointment.instances.length }} acquéreurs ont planifié leur rendez-vous 
                                        </template>

                                        <br>
                                        Cliquez pour afficher le planning des rendez-vous à honorer
                                    </v-tooltip>
                                </template>
                            </ManageAppointmentSessionPlanningDialog>
                        </td>

                        <td>
                            <ConfirmationDialog v-if="appointment.instances.length === 0" @confirm="deleteAppointmentSession(appointment)" text="Voulez-vous vraiment supprimer cette session de rendez-vous ?">
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on: dialog }">
                                            <v-btn class="ml-2" color="red" x-small text icon v-on="{ ...dialog, ...tooltip }">
                                                <v-icon x-small> fas fa-times </v-icon>
                                            </v-btn>
                                        </template>
                                        Supprimer
                                    </v-tooltip>
                                </template>
                            </ConfirmationDialog>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>

        <!-- Pagination -->
        <v-card v-if="totalPages > 1" class="d-flex align-center pa-2">
            <v-spacer />

            <v-pagination v-model="page" @input="fetchAppointments(true, false)" :length="totalPages" total-visible="5" color="app-blue" />
        </v-card>
    </div>
</template>

<script>
import documentsMixin from '../../mixins/documents.js';
import UserAutocomplete from '../../components/widgets/UserAutocomplete.vue';
import ConfirmationDialog from '../../components/dialogs/ConfirmationDialog.vue';
import ProgramAutocomplete from '../../components/widgets/ProgramAutocomplete.vue';
import CreateAppointmentSessionDialog from '../../components/appointments/CreateAppointmentSessionDialog.vue';
import ManageAppointmentSessionPlanningDialog from '../../components/appointments/ManageAppointmentSessionPlanningDialog.vue';

export default {
    name: 'Appointments',

    mixins: [documentsMixin],

    components: {
        UserAutocomplete,
        ConfirmationDialog,
        ProgramAutocomplete,
        CreateAppointmentSessionDialog,
        ManageAppointmentSessionPlanningDialog
    },

    data: () => ({
        page: 1,
        limit: 30,
        totalPages: 0,
        totalCount: 0,

        appointments: [],

        search: {
            subjects: null,
            programs: null,
            intervenants: null,
            passedSessions: false,
            futureSessions: false
        },

        order: 'subject',
        defaultOrder: 'subject',
        by: 'asc',
        defaultBy: 'asc',
        sortList: [
            {
                text: 'Objet',
                value: 'subject',
                asc: [{ field: 'subject', order: 'asc' }],
                desc: [{ field: 'subject', order: 'desc' }]
            },
            {
                text: 'Période',
                value: 'maxDate',
                asc: [{ field: 'maxDate', order: 'asc' }],
                desc: [{ field: 'maxDate', order: 'desc' }]
            }
        ]
    }),

    watch: {
        'search.passedSessions'() {
            if (this.search.passedSessions) {
                this.search.futureSessions = false;
            }
        },

        'search.futureSessions'() {
            if (this.search.futureSessions) {
                this.search.passedSessions = false;
            }
        }
    },

    methods: {
        doSearch() {
            clearTimeout(this.searchTimeout);
            this.searchTimeout = setTimeout(() => {
                this.fetchAppointments();
            }, 500);
        },

        toggleOrder() {
            this.by = this.by === 'asc' ? 'desc' : 'asc';
            this.fetchAppointments();
        },

        async fetchAppointments(push = true, resetPage = true) {
            try {
                this.setLoading(true);

                if (resetPage) {
                    this.page = 1;
                }

                if (push && this.doPush(this.displayQuery)) {
                    this.$router.push({ query: this.displayQuery });
                }

                const { appointments, count, totalPages, err } = await this.repos.appointments.getAppointments(this.query);
                if (err) {
                    throw new Error(err);
                }
                this.appointments = appointments;
                this.totalCount = count;
                this.totalPages = totalPages;
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors du chargement des rendez-vous',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        },

        async deleteAppointmentSession(appointment) {
            try {
                this.setLoading(true);

                const { err } = await this.repos.appointments.deleteAppointment(appointment.id);
                if (err) {
                    throw new Error(err);
                }
                this.fetchAppointments();
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors de la suppression de la session de rendez-vous',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        },

        parseQuery(query) {
            this.page = parseInt(query.page) || 1;
            this.order = query.order || this.defaultOrder;
            this.by = query.by || this.defaultBy;
            this.search.quickSearch = query.quickSearch || null;

            if (query.subjects) {
                this.search.subjects = query.subjects.split(',');
            }

            if (query.programs) {
                this.search.programs = query.programs.split(',').map((p) => parseInt(p));
            }

            if (query.intervenants) {
                this.search.intervenants = query.intervenants.split(',').map((p) => parseInt(p));
            }

            if (query.passedSessions === '1') {
                this.search.passedSessions = true;
            }

            if (query.futureSessions === '1') {
                this.search.futureSessions = true;
            }
        },

        doPush(query) {
            return (Object.keys(query).length !== Object.keys(this.$route.query).length) || !Object.keys(query).every((key) => query[key] === this.$route.query[key]);
        }
    },

    computed: {
        query() {
            const order = this.sortList.find((i) => i.value === this.order);
            let items = this.by === 'asc' ? order.asc : order.desc;
            const orderBy = items.map((s) => `${s.field}:${s.order}`).join(',');
            const query = {
                page: this.page,
                limit: this.limit,
                orderBy,
                include: 'programs,intervenants,disponibilities,categories,buyers,instances'
            };

            if (this.search.quickSearch) {
                query.quickSearch = this.search.quickSearch;
            }

            if (Array.isArray(this.search.subjects) && this.search.subjects.length > 0) {
                query.subjects = this.search.subjects.join(',');
            }

            if (Array.isArray(this.search.programs) && this.search.programs.length > 0) {
                query.programs = this.search.programs.join(',');
            }

            if (Array.isArray(this.search.intervenants) && this.search.intervenants.length > 0) {
                query.intervenants = this.search.intervenants.join(',');
            }

            if (this.search.passedSessions) {
                query.passedSessions = '1';
            }

            if (this.search.futureSessions) {
                query.futureSessions = '1';
            }

            return query;
        },

        displayQuery() {
            const query = JSON.parse(JSON.stringify(this.query));
            delete query.limit;
            delete query.include;
            delete query.debug;

            if (query.page === 1) {
                delete query.page;
            }

            if (Array.isArray(this.search.subjects) && this.search.subjects.length > 0) {
                query.subjects = this.search.subjects.join(',');
            }

            if (Array.isArray(this.search.programs) && this.search.programs.length > 0) {
                query.programs = this.search.programs.join(',');
            }

            if (Array.isArray(this.search.intervenants) && this.search.intervenants.length > 0) {
                query.intervenants = this.search.intervenants.join(',');
            }

            if (this.search.passedSessions) {
                query.passedSessions = '1';
            }

            if (this.search.futureSessions) {
                query.futureSessions = '1';
            }

            delete query.orderBy;
            if (this.order !== this.defaultOrder) {
                query.order = this.order;
            }

            if (this.by !== this.defaultBy) {
                query.by = this.by;
            }

            return query;
        }
    },

    created() {
        this.parseQuery(this.$route.query);
        this.fetchAppointments(false, false);
        this.$nextTick(() => {
            this.$refs.programAutocomplete.fetchPrograms({ limit: 10000 });
            this.$refs.userAutocomplete.fetchUsers({ limit: 10000 });
        });
    }
};
</script>