<template>
    <v-dialog v-model="isOpen" width="900px">
        <template v-slot:activator="{ on }">
            <slot name="activator" v-bind="{ on }" />
        </template>

        <v-card>
            <v-toolbar dense flat>
                <v-toolbar-title class="title primary--text">
                    Planifier une nouvelle session de rendez-vous
                </v-toolbar-title>

                <v-spacer />

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="isOpen = false" color="primary" icon v-on="on">
                            <v-icon> far fa-times-circle </v-icon>
                        </v-btn>
                    </template>
                    Fermer la fenêtre
                </v-tooltip>
            </v-toolbar>

            <v-divider class="mb-4" />

            <v-card-text>
                <p>
                    Saisissez ici le contexte complet des rendez-vous à organiser (programme, type de rendez-vous, acquéreurs, intervenants, planning, ...).
                </p>

                <ValidationObserver ref="observer">
                    <table style="border-spacing: 10px; width: 100%">
                        <!-- Programme -->
                        <tr>
                            <td>
                                Programme <span class="red--text"> * </span>
                            </td>

                            <td colspan="2">
                                <ValidationProvider v-slot="{ errors, failed }" name="Objet" rules="required">
                                    <ProgramAutocomplete v-model="appointment.programId" ref="programAutocomplete" label="Programme" :errors="errors" :failed="failed" outlined dense />
                                </ValidationProvider>
                            </td>
                        </tr>
                       
                        <!-- Objet -->
                        <tr>
                            <td>
                                Objet <span class="red--text"> * </span>
                            </td>

                            <td colspan="2">
                                <ValidationProvider v-slot="{ errors, failed }" name="Objet" rules="required">
                                    <v-select v-model="appointment.subject" :items="subjectItems" label="Objet" :menu-props="{ bottom: true, offsetY: true }" :error-messages="errors" :hide-details="!failed" outlined dense />
                                </ValidationProvider>
                            </td>
                        </tr>

                        <!-- Durée estimée -->
                        <tr>
                            <td>
                                Durée estimée <span class="red--text">*</span>
                            </td>

                            <td colspan="2">
                                <ValidationProvider v-slot="{ errors, failed }" name="Durée estimée" rules="required">
                                    <v-select v-model="appointment.duration" :items="durationItems" label="Durée estimée" :menu-props="{ bottom: true, offsetY: true }" :error-messages="errors" :hide-details="!failed" outlined dense />
                                </ValidationProvider>
                            </td>
                        </tr>

                        <!-- Plage de dates proposée -->
                        <tr>
                            <td>
                                <span>
                                    Plage de dates proposée <span class="red--text">*</span>
                                </span>
                            </td>

                            <td>
                                <ValidationProvider v-slot="{ errors, failed }" name="De" rules="required">
                                    <DatePickerMenu v-model="appointment.minDate" classes="flex-grow-0" label="De" :errors="errors" :failed="failed" />
                                </ValidationProvider>
                            </td>

                            <td>
                                <ValidationProvider v-slot="{ errors, failed }" name="A" rules="required">
                                    <DatePickerMenu v-model="appointment.maxDate" classes="flex-grow-0" label="A" :errors="errors" :failed="failed" />
                                </ValidationProvider>
                            </td>
                        </tr>

                        <!-- Lieu -->
                        <tr>
                            <td>
                                Lieu
                            </td>

                            <td colspan="2">
                                <v-text-field v-model="appointment.location" label="Lieu" hide-details outlined dense />
                            </td>
                        </tr>

                        <tr>
                            <td>
                                Intervenant <span class="red--text"> * </span>
                            </td>

                            <td colspan="2">
                                <ValidationProvider v-slot="{ errors, failed }" name="Objet" rules="required">
                                    <UserAutocomplete v-model="appointment.intervenantId" ref="userAutocomplete" label="Intervenant" :errors="errors" :failed="failed" outlined dense />
                                </ValidationProvider>
                            </td>
                        </tr>
                    </table>
                </ValidationObserver>
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-btn @click="submit()" color="primary" small> Enregistrer </v-btn>

                <v-spacer />

                <v-btn @click="isOpen = false" small> Annuler </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { format, endOfDay } from 'date-fns';
import DatePickerMenu from '../widgets/DatePickerMenu.vue';
import UserAutocomplete from '../widgets/UserAutocomplete.vue';
import ProgramAutocomplete from '../widgets/ProgramAutocomplete.vue';

export default {
    name: 'ManageAppointmentDialog',

    components: {
        DatePickerMenu,
        UserAutocomplete,
        ProgramAutocomplete
    },

    data: () => ({
        isOpen: false,
        additionalDataFetched: false,

        appointment: {},

        durationItems: [
            { text: '30min', value: 30 },
            { text: '1h', value: 60 },
            { text: '1h30', value: 90 },
            { text: '2h', value: 120 },
            { text: '2h30', value: 150 },
            { text: '3h', value: 180 },
            { text: '3h30', value: 210 },
            { text: '4h', value: 240 },
            { text: '4h30', value: 270 },
            { text: '5h', value: 300 },
            { text: '5h30', value: 330 },
            { text: '6h', value: 360 }
        ],

        subjectItems: [
            'Choix et options',
            'Visite cloisons',
            'Visite pré-livraison',
            'Livraison et remise des clés'
        ]
    }),

    watch: {
        isOpen() {
            if (this.isOpen) {
                this.appointment = {
                    duration: 30
                };
                if (this.appointmentId) {
                    this.fetchAppointment(this.appointmentId);
                }

                this.$nextTick(() => {
                    this.$refs.observer.reset();
                    if (!this.additionalDataFetched) {
                        this.additionalDataFetched = true;
                        this.$refs.programAutocomplete.fetchPrograms({ limit: 10000 });
                        this.$refs.userAutocomplete.fetchUsers({ limit: 10000 });
                    }
                });
            }
        }
    },

    methods: {
        async fetchAppointment(appointmentId) {
            try {
                this.setLoading(true);

                const query = {
                    include: 'programs,intervenants',
                    appointments: [appointmentId]
                };

                const { appointments: [appointment], err } = await this.repos.appointments.getAppointments(query);
                if (err) {
                    throw new Error(err);
                }
                this.appointment = appointment;
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors du chargement de la session de rendez-vous',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        },

        async submit() {
            const valid = await this.$refs.observer.validate();
            if (!valid) {
                return;
            }

            try {
                this.setLoading(true);

                if (this.appointment.maxDate) {
                    this.appointment.maxDate = format(endOfDay(new Date(this.appointment.maxDate)), 'yyyy-MM-dd HH:mm:ss');
                }

                const body = { appointment: this.appointment };

                const { err } = await this.repos.appointments.createAppointment(body);

                if (err) {
                    throw new Error(err);
                }

                this.isOpen = false;
                this.$notify({
                    title: 'Succès',
                    text: 'Le nouvelle session de rendez-vous a bien été créée',
                    type: 'success'
                });
                this.$emit('created');
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors de la création de la session de rendez-vous',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        }
    }
};
</script>
