<template>
    <v-autocomplete v-model="selectedUsers" :items="displayUsers" :loading="isLoading" :disabled="disabled" :hint="hint" :item-value="returnObject ? undefined : 'id'" clear-icon="far fa-times-circle" :class="{ 'flex-grow-0': flexGrow0 }" :search-input.sync="search" @focus="$emit('focus')" :menu-props="{ bottom: true, offsetY: true }" @change="$emit('input', selectedUsers)" :multiple="multiple" :label="label" :placeholder="placeholder" :error-messages="errors" :hide-details="!hint && !failed" persistent-hint no-data-text="Commencez à taper pour lancer une recherche" background-color="white" no-filter outlined clearable dense>
        <template v-slot:selection="data">
            <v-chip v-if="data.index === 0" small label>
                <v-avatar color="blue white--text text-uppercase" class="mr-2"> 
                    <v-img v-if="data.item.profilePictureDocumentId" :src="getDocumentUrl(data.item.profilePictureDocumentId)" />
                    <span v-else> {{ data.item.name.slice(0, 1) }} </span>
                </v-avatar>

                {{ data.item.name }} {{ data.item.firstnname }}
            </v-chip>

            <span v-if="data.index === 1" class="grey--text text-caption">
                (+{{ selectedUsers.length - 1 }} autres)
            </span>
        </template>

        <template v-slot:item="{ item }">
            <v-list-item-avatar class="blue white--text text-uppercase headline">
                <v-img v-if="item.profilePictureDocumentId" :src="getDocumentUrl(item.profilePictureDocumentId)" />
                <span v-else> {{ item.name.slice(0, 1) }} </span>
            </v-list-item-avatar>

            <v-list-item-content>
                <v-list-item-title>
                    {{ item.name }} {{ item.firstname }}

                    <template v-if="item.job">
                        - <span class="grey--text"> {{ item.job }} </span>
                    </template>
                </v-list-item-title>

                <v-list-item-subtitle>
                    {{ item.email }}
                </v-list-item-subtitle>
            </v-list-item-content>
        </template>
    </v-autocomplete>
</template>

<script>
import documentsMixin from '../../mixins/documents.js';

export default {
    name: 'UserAutocomplete',

    mixins: [documentsMixin],

    props: {
        value: { required: true },
        label: { type: String },
        placeholder: { type: String },
        hint: { type: String },
        disabled: { type: Boolean, default: false },
        multiple: { type: Boolean, default: false },
        noDynamicSearch: { type: Boolean, default: false },
        flexGrow0: { type: Boolean, default: false },
        errors: { type: Array },
        failed: { type: Boolean, default: false },
        returnObject: { type: Boolean, default: false },
        initUsers: { type: Array }
    },

    data: () => ({
        selectedUsers: null,
        users: [],
        displayUsers: [],
        isLoading: false,
        search: null,
        searchTimeout: null
    }),

    watch: {
        value() {
            this.selectedUsers = this.value;
        },

        search(search) {
            if (this.noDynamicSearch) {
                if (typeof search !== 'string' || search.length.length < 3) {
                    this.displayUsers = this.users;
                } else {
                    this.displayUsers = this.users.filter((p) => {
                        return (p.name && p.name.toLowerCase().includes(search.toLocaleLowerCase()))
                            || (p.firstname && p.firstname.toLowerCase().includes(search.toLocaleLowerCase()))
                            || (p.job && p.job.toLowerCase().includes(search.toLocaleLowerCase()));
                    });
                }
                return;
            }

            clearTimeout(this.searchTimeout);
            this.searchTimeout = setTimeout(() => {
                if (typeof search !== 'string' || search.length.length < 3) {
                    return;
                }
                if (this.isLoading) {
                    return;
                }

                this.isLoading = true;
                const query = {
                    limit: 5,
                    quickSearch: search
                };
                this.fetchUsers(query);
            }, 500);
        },

        initUsers() {
            this.displayUsers = this.initUsers;
        },

        selectedUsers() {
            if (Array.isArray(this.selectedUsers)) {
                const value = this.selectedUsers.map((selectedUser) => this.displayUsers.find((displayUser) => displayUser.id === selectedUser.id));
                this.$emit('selected', value);
            } else if (this.selectedUsers) {
                const value = this.displayUsers.find((displayUser) => displayUser.id === this.selectedUsers);
                this.$emit('selected', value);
            } else {
                this.$emit('selected', null);
            }
        }
    },

    computed: {
        noDataText() {
            if (this.noDynamicSearch) {
                return 'Aucun groupe disponible';
            } else {
                return 'Commencez à taper pour lancer une recherche';
            }
        }
    },

    methods: {
        async fetchUsers(query) {
            try {
                this.isLoading = true;
                const { users } = await this.repos.users.getUsers(query);
                this.users = users;
                this.displayUsers = users;
            } catch (err) {
                console.error(err);
            } finally {
                this.isLoading = false;
            }
        },

        reset() {
            this.selectedUsers = [];
        }
    },

    created() {
        this.selectedUsers = this.value;
        if ((Array.isArray(this.selectedUsers) && this.selectedUsers.length > 0) || typeof this.selectedUsers === 'number') {
            const users = Array.isArray(this.selectedUsers) ? this.selectedUsers : [this.selectedUsers];
            this.fetchUsers({ users });
        }
        if (this.initUsers) {
            this.displayUsers = this.initUsers;
        }
    }
};
</script>